
const Title = () => {
  return (
    <>
      <h1 className="text-4xl flex justify-center border-b-4 py-4">Atcoder Diff Sort</h1>
    </>
  )
}

export default Title
